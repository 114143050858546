import React from 'react'
import { Link } from 'gatsby'
import { Col, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import Layout from '/src/components/layout'
import HeroImage from '/src/components/layout/HeroImage'
import { LinkedList } from '/src/components/common'

const LinkedListItem = ({ title, content }) => {
  return (
    <>
      <h5 className="mb-3">{title}</h5>
      <p>{content}</p>
    </>
  )
}

const HealthSafetyPage = () => {
  return (
    <Layout hero={<HeroImage title="Health, Safety & Environment" />} mainContainer={{fluid: "xxl"}}>
      <Row className="d-flex justify-content-center content-row">
        <Col sm={10}>
          <div className="mb-5">
            <Link className="back-to-link" to='/community/about-us'>
              <FontAwesomeIcon icon={faArrowLeft} />
              Back to about us
            </Link>
          </div>
          <Row className="d-flex align-items-center mt-5 mb-5">
            <Col md={8}>
              <p>Orrcon Steel takes its Health, Safety and Environment (HSE) responsibilities extremely seriously and places it as a priority to ensure we operate a sustainable business.</p>
              <p>All levels within the business structure have clear accountabilities and responsibilities to provide clear guidance in how to provide and maintain a safe and healthy workplace. The Orrcon Steel Team continually strive to improve the quality of working life and business performance, reduce the risks of illness and injury and boost the well-being of our people.</p>
              <p>Orrcon Steel has worked closely with parent company BlueScope Steel to develop and implement a robust HSE Management system (aligned to AS4801 & ISO14001) to manage the operating risks within the business. The HSE Management system provides the tools to Site Management Teams to identify, assess and develop sufficient controls for the operating risks and to train our workforce in implementing those controls.</p>
              <p>The system also incorporates a HSE Activity Scheduler to provide structure in undertaking actual risk mitigation activities in accordance to State legislation, Corporate Governance and BlueScope HSE Standards.</p>
              <p>Orrcon Steel is committed to minimising the environmental impacts and resource usage at our sites. Programs we have in place include quantification of greenhouse gas emissions and rates of general waste and recyclable streams generated across our sites.</p>
            </Col>
            <Col md={4}>
              <ul>
                <li>We continually strive for zero harm</li>
                <li>Working safely is everyone's responsibility and a condition of employment</li>
                <li>Employee involvement and action is essential</li>
                <li>All deficiencies must be addressed promptly</li>
                <li>Safe equipment and systems of work are critical to provide a safe work environment.</li>
                <li>Training is essential to ensure a safe work environment</li>
                <li>We will get the level of safety we demonstrate we want</li>
              </ul>
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout>
  )
}

export default HealthSafetyPage

export const Head = () => <title>Health, Safety & Environment | Orrcon Steel</title>
